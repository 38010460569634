@import "./variable.scss";
@import "./mixins.scss";

// html{overflow: hidden;}
body {
  min-width: 990px;
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Medium, PingFang SC, Source Han Sans CN,
    Microsoft YaHei, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
@media screen and (max-width: 600px) {
  body {
    min-width: inherit !important;
  }
}
/* common class */
.right {
  float: right;
  margin: 10px;
}
.left {
  float: left;
}
.pointer {
  cursor: pointer;
}
.bold-font {
  font-weight: bold;
}
.relative {
  position: relative;
}

.inline-block {
  display: inline-block;
}
.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}
.clearfix {
  @include clearfix;
}
.relative {
  position: relative;
}

.common-opt {
  color: $blue-5;
  cursor: pointer;
  margin-right: 8px;
}

.form-row {
  margin-bottom: 20px;
  .ant-col {
    height: inherit !important;
  }
}

/* common class */

/* flex style */
.flex {
  display: flex;
}
.flex-between {
  display: flex !important;
  justify-content: space-between !important;
}
.flex-vertical-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-h-between {
  display: flex;
  justify-content: space-between;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-end-item {
  display: flex;
  justify-content: flex-end;
}
.flex-x-center {
  display: flex;
  justify-content: center;
}

.flex-right {
  display: flex;
  justify-content: right;
}

.flex-center-container {
  display: flex;
  justify-content: center;
}

.vertical-center {
  display: flex !important;
  align-items: center;
}

/* flex style */

/* common component class */

.nav-menu {
  height: 60px;
  .nav-menu-item {
    float: left;
    box-sizing: border-box;
    padding: 8px 0;
    margin-right: 40px;
    height: 60px;
    line-height: 60px;
    font-size: $middle-font;
    color: $grey-2;
    cursor: pointer;
    position: relative;
    &::after {
      @include ab-vertical-center;
      content: "";
      height: 14px;
      width: 1px;
      right: 0;
      background: rgba(255, 255, 255, 0.2);
    }
    a {
      transition: color 0s !important;
    }
    &.active {
      border-bottom: 2px solid $blue-1;
      .menu-item-content {
        color: $blue-1;
      }
      a {
        background: $white !important;
        color: $blue-2 !important;
      }
    }
    .menu-item-content {
      display: flex;
      height: 44px;
      align-items: center;
      border-radius: 4px;
    }
  }
  .nav-sub-menu {
    .nav-sub-menu-panel {
      position: absolute;
      left: 0;
      top: 60px;
      width: 320px;
      background: $white;
      padding: 14px 20px;
      z-index: $zindex-level-1;
      border: 1px solid $grey-3;
      box-shadow: 0px 4px 8px 0px rgba(67, 80, 107, 0.45);
      .nav-menu-group {
        position: relative;
        margin-top: 10px;
        padding: 26px 0 16px;
        @include clearfix;
        .title-bar {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          transform: translateY(-50%);
          .title {
            padding: 0 6px;
            font-size: 14px;
            line-height: 20px;
            color: $blue-2;
          }
          .line {
            flex: 1;
            height: 1px;
            background-color: $grey-3;
          }
        }
        .nav-menu-group-item {
          float: left;
          line-height: 17px;
          font-size: 12px;
          color: $grey-1;
          padding: 0 6px;
          margin-right: 28px;
          margin-bottom: 8px;
          &:hover {
            color: $blue-2;
            text-decoration: underline;
          }
        }
        .nav-menu-group-item-active {
          float: left;
          line-height: 17px;
          font-size: 12px;
          color: $white;
          padding: 0 6px;
          background: $blue-2;
          margin-right: 28px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.common-panel {
  background: $white;
  border-radius: 6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.common-container {
  box-sizing: border-box;
  min-height: calc(100vh - 100px);
  width: 100%;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.padding-panel {
  padding: 20px 20px;
}

.nav-font {
  color: $white;
  font-size: $middle-font;
  font-weight: 400;
}

.option-item {
  display: inline-block;
  padding: 0 10px;
  position: relative;
  color: $blue-2;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:first-child {
    padding-left: 0;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $grey-4;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}

.dashed-line {
  box-sizing: border-box;
  border-top: 1px dashed $grey-5;
  height: 1px;
}

/* common component class */

/* layout class */
.container {
  width: 100%;
  min-width: $min-width;
  min-height: 100vh;
}

/* layout class */
.panel {
  position: relative;
  padding: 20px;
  // margin-top: 1.429rem;
  margin-bottom: 1.429rem;
  background-color: $white;
  border: 0 solid transparent;
  border-radius: 0.143rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
/* antd class */


// .ant-form-item {
//   margin-bottom: 16px;
// }

/* antd class */

/* custom antd class */
.form-drawer .ant-drawer-body {
  padding: 24px 42px 24px 24px !important;
}
.custom-input-tab .ant-tabs-tab-active {
  background-color: $blue-2 !important;
  color: $white !important;
  border-radius: 6px;
}

.custom-input-tab .ant-tabs-tab {
  line-height: 1.2 !important;
}

.custom-input-tab .ant-tabs-left-bar .ant-tabs-tab {
  margin: 0 0 8px 0 !important;
}
.ant-modal{
  position: absolute !important;top:0;left: 0;right: 0;bottom :0;margin:auto;
}
.common-modal {
 
  .ant-modal-header {
    padding: 5px 10px !important;
    background: linear-gradient(
      180deg,
      rgba(167, 195, 226, 1) 0%,
      rgba(230, 239, 253, 1) 100%
    );
    border-radius: 4px 4px 0px 0px;
  }
  .ant-modal-close-x {
    height: 33px;
    line-height: 33px;
    width: 33px;
  }
  .ant-modal-footer {
    padding: 8px 30px 20px 0 !important;
    border-top: none;
    .ant-btn {
      padding: 0 20px;
    }
  }
}

.block-label {
  .ant-form-item-label {
    display: flex !important;
    align-items: center;
    line-height: 1 !important;
  }
  .ant-form-item {
    line-height: 1 !important;
  }
  .ant-form-item-control {
    line-height: 1 !important;
  }
  .ant-input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .ant-select-selection {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .ant-form-item-label {
    justify-content: center;
    background: #e7e7e9;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    label {
      color: $grey-6;
    }
    label::after {
      content: "";
      margin: 0;
    }
  }
}

.custom-antd-table {
  height: calc(100vh - 200px);
  overflow: auto;
  td {
    padding: 6px 0px !important;
    font-size: 13px;
  }
  .smallTable {
    td {
      padding: 8px !important;
    }
  }
  tr:last-child {
    td {
      border-bottom: 1px solid $grey-8 !important;
    }
  }
  tr:hover {
    > td {
      background-color: $blue-3 !important;
    }
  }
  th {
    font-weight: bold !important;
    padding: 10px 0px !important;
    font-size: 13px !important;
  }
}
/* custom antd class */
.ant-btn-primary {
  background: #35b1f6;
  border-color: #35b1f6 !important;
}
.innerBox {
  background: white;
  width: calc(100% - 40px);
  margin: 20px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 0 !important;
  // padding-bottom: 0 !important;
}

.searchBox {
  box-shadow: 0px 0px 4px 0px rgba(65, 116, 238, 0.16);
  background: white;
  padding: 20px 20px;
  margin-bottom: 1.429rem;
  .ant-input-group-addon {
    background: rgba(231, 231, 233, 1);
  }
  .right button:nth-child(1) {
    margin-right: 10px;
  }
  .box {
    margin-right: 1%;
    // width: 30%;
    margin: 10px;
  }
  .left.clearfix {
    width: 60%;
  }
  .ant-btn {
    padding: 0px 30px;
  }
  .label {
    box-sizing: border-box;
    background: #e7e7e9;
    border: 1px solid #d9d9d9;
    display: inline-block;
    padding: 0px 11px;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    border-radius: 4px 0 0 4px;
    border-right: none;
  }
  .ant-calendar-picker-input {
    border-radius: 0 4px 4px 0;
  }
  .ant-calendar-picker {
    width: 70%;
  }
  .ant-select-selection {
    border-radius: 0 4px 4px 0;
  }
  .ant-cascader-picker {
    border-radius: 0 4px 4px 0;
  }
  .ant-input.ant-cascader-input {
    border-radius: 0 4px 4px 0;
  }
}
.spSearchBox {
  @extend .searchBox;
  .left.clearfix {
    width: 30%;
  }
  .right {
    width: 45%;
    text-align: right;
  }
  .slectUl {
    display: inline-block;
    margin-right: 10px;
    li {
      display: inline-block;
      color: #000000;
      margin-right: 24px;
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
  button {
    height: 40px;
  }
}
.btns {
  text-align: center;
  a {
    margin-right: 20px;
  }
  button {
    padding: 11px 47px;
    line-height: 0;
    margin-right: 15px;
  }
  button:nth-last-child(0) {
    margin-right: 0;
  }
}
.greenBtn {
  background: rgba(50, 190, 170, 1) !important;
  color: white !important;
  border-color: rgba(50, 190, 170, 1) !important;
}
.textAlign{
  text-align: center;
}
.left-text {
  position: absolute;
  left: 0px;
}
.require-icon {
  color: red;
}
.gray-font {
  color: #a7a7a7;
  font-size: 12px;
}
.red-font {
  color: red;
}
.small-font {
  font-size: 12px;
}
.bottom-20 {
  margin-bottom: 20px;
}
.add-course-btn {
  width: 100%;
  height: 34px;
  color: #1a9afd;
  margin-top: 10px;
  margin-bottom: 10px;
  // background-color: #f3f7f9 !important;
  border: 1px dashed #589ffc !important;
}
.w-num {
  width: 70%;
}

.left-tree {
  width: 380px;
  min-height: calc(100vh - 200px);
  margin-right: 20px;
  border-right: 1px solid #f0f2f5;
}
.right-table {
  width: calc(100% - 420px);
}

.sortBtn {
  margin: 0px 5px;
}
.nodeName {
  width: 70px;
  // white-space: normal;
  padding-right: 5px;
  margin-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-tree {
  .tree-node {
    width: 150px;
    padding-right: 0px;
    margin-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-tree-title {
  @extend .tree-node;
  font-size: 14px;
  display: inline-block;
}
.ok-btn {
  margin-right: 50px;
}
.right-oper {
  position: absolute;
  right: calc(100% - 400px);
  text-align: right;
}
.h-panel {
  min-height: 85vh;
}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

.tab-content {
  min-height: 500px;
}

.move-btn {
  margin-right: 5px;
}

/* antd class */
.ant-input-number {
  width: 100% !important;
}
.center-modal {
  .ant-modal-title {
    text-align: center !important;
  }
  .ant-modal-footer {
    text-align: center !important;
  }
}
.ant-modal-footer {
  padding: 10px 24px !important;
}
.white-space {
  white-space: pre-line;
  margin-bottom: 0;
}
.ant-comment-inner {
  border-bottom: 1px solid #f0f2f5;
}
.ant-comment-content-author-name {
  font-size: 14px !important;
  font-weight: 500;
  color: rgba(31, 43, 79, 1);
}
.ant-tree-iconEle ant-tree-icon__customize {
  text-align: left;
}
.whiteTable {
  .ant-table-tbody > tr {
    background: white !important;
  }
}
.clearFix:after {
  clear: both;
  visibility: hidden;
  height: 0;
  content: "";
  display: block;
}
.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.scroll2::-webkit-scrollbar {
  width: 6px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.08);
}
.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: white;
}
.noScrol::-webkit-scrollbar {
  height: 0px;
}
#app,
div,
body {
  @extend .scroll;
}
ul {
  @extend .scroll;
  @extend .scroll2;
}

th.column-size,
td.column-size {
  font-size: 13px !important;
}
.allWrap {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
.ant-table.ant-table-bordered .ant-table-container{border-bottom: 1px solid #f0f0f0 !important;}
.ant-btn-primary-disabled, .ant-btn-primary.disabled, .ant-btn-primary[disabled], .ant-btn-primary-disabled:hover, .ant-btn-primary.disabled:hover, .ant-btn-primary[disabled]:hover, .ant-btn-primary-disabled:focus, .ant-btn-primary.disabled:focus, .ant-btn-primary[disabled]:focus, .ant-btn-primary-disabled:active, .ant-btn-primary.disabled:active, .ant-btn-primary[disabled]:active, .ant-btn-primary-disabled.active, .ant-btn-primary.disabled.active, .ant-btn-primary[disabled].active{
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.grayBtn {
  background: rgba(247, 247, 247, 1) !important;
  border: 1px solid #e9e9e9 !important;
  color: #445179 !important;
}

.ant-table:not(.ant-table-fixed-header) .ant-table-content {
  overflow: hidden;
}
.ant-table.ant-table-bordered .ant-table-container{
  margin-bottom: 20px;
}
.inputBox{
  position: relative;
  p{position: absolute;right: 5px;top: 5px;color:rgba(189,189,189,1);}
}
.userInfoAleft .ant-modal-body{padding: 0;}
.userInfoAleft .ant-modal-header{background:#1a9afd;
  .ant-modal-title{color: white;}
  
}
.userInfoAleft .anticon{color: white !important;}
.ant-checkbox-group {
  width: 100% !important;
} 



.hovers{
  display: none !important;
}
.hbox:hover{
  .hovers{
    display: inline-block !important;
  }
}